export const formatQuantity = (quantity: number | string, precision = 2) => {
  let value = quantity;

  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "string") {
    return value;
  }

  return value.toFixed(precision).replace(".", ",");
};

export const formatPrice = (quantity: number | string) => {
  return formatQuantity(quantity, 2);
};

export const formatWeight = (quantity: number | string) => {
  return formatQuantity(quantity, 3);
};

export const formatMargin = (quantity: number | string) => {
  return formatQuantity(quantity, 2);
};

export const parsePrice = (price: string) => {
  // Check if number already
  if (typeof price === "number") {
    return price;
  }

  if (price === null || price === undefined || price === "") {
    return 0;
  }

  return parseFloat(price.replace(",", "."));
};

export const parseWeight = (weight: string) => {
  return parsePrice(weight);
};

export const parseMargin = (margin: string) => {
  return parsePrice(margin);
};

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export const removeHTMLTags = (htmlString: string) => {
  if (!htmlString) {
    return "";
  }

  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, "text/html");
  // Extract text content
  const textContent = doc.body.textContent || "";
  // Trim whitespace
  return textContent.trim();
};
