import { useMutation, useQuery } from "@apollo/client";
import { Download, Upload } from "@mui/icons-material";
import { Button, CircularProgress, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { EditableDataGrid } from "../../../shared/table/EditableDataGrid";
import { priceRenderer } from "../../../shared/table/renderers/renderCell";
import { removeHTMLTags } from "../../../shared/utils";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import { UpdatePricesImportDialog } from "../../update-prices-import/update-prices-import-dialog";
import { INGREDIENTS_QUERY } from "./ingredients.query";

export const IngredientsPage = () => {
  const [importOpen, setImportOpen] = useState(false);
  const { loading, error, data } = useQuery(INGREDIENTS_QUERY);
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);
  const navigate = useNavigate();

  if (loading || data.loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  const columns: GridColDef<(typeof data.ingredients)[number]>[] = [
    { field: "eanCode", headerName: "EAN", width: 110, editable: false },
    {
      field: "name",
      headerName: "Nazwa",
      width: 150,
      editable: false,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Link component={RouterLink} to={`/ingredients/${params.row.id}`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "price",
      headerName: "Cena",
      width: 60,
      type: "number",
      editable: false,
      renderCell: priceRenderer,
    },
    {
      field: "category",
      headerName: "Kategoria",
      renderCell: (params) => params.row.category?.name || "",
      width: 120,
      editable: false,
    },
    {
      field: "isAllergen",
      headerName: "Alergen",
      width: 90,
      editable: false,
      type: "boolean",
    },
    {
      field: "packageWeight",
      headerName: "Waga opakowania",
      width: 120,
      editable: false,
      type: "number",
    },
    {
      field: "unitType",
      headerName: "Jednostka",
      width: 90,
      editable: false,
      type: "string",
    },
    {
      field: "description",
      headerName: "Opis",
      width: 200,
      editable: false,
      type: "string",
      renderCell: (params) => removeHTMLTags(params.row.description) || "",
    },
  ];

  return (
    <>
      <EditableDataGrid
        modelType="ingredient"
        nameColumn="name"
        title="Składniki"
        refetchQueries={["IngredientsQuery"]}
        noAdding={true}
        noActions={true}
        batchActionComponents={[
          (toolbarProps) => (
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                navigate("/new-ingredient");
              }}
              {...toolbarProps}
            >
              Nowy składnik
            </Button>
          ),
          (toolbarProps) => (
            <Button
              variant="outlined"
              color="warning"
              size="small"
              startIcon={<Download />}
              onClick={() => {
                window.open("/export");
              }}
              {...toolbarProps}
            >
              Export
            </Button>
          ),
          (toolbarProps) => (
            <Button
              variant="outlined"
              color="warning"
              size="small"
              startIcon={<Upload />}
              onClick={() => {
                setImportOpen(true);
              }}
              {...toolbarProps}
            >
              Aktualizacja cen
            </Button>
          ),
        ]}
        onSave={(data, done, err) => {}}
        onRemove={(data, done, err) => {
          removeResource({
            variables: {
              id: [data.id],
              type: "ingredient",
            },
            refetchQueries: ["IngredientsQuery"],
          })
            .then(done)
            .catch(err);
        }}
        rows={data.ingredients}
        columns={columns}
        checkboxSelection={false}
      />

      <UpdatePricesImportDialog
        open={importOpen}
        onClose={() => setImportOpen(false)}
      />
    </>
  );
};
