import { useMutation, useQuery } from "@apollo/client";
import { Add } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useEffect, useRef, useState } from "react";
import { ErrorInfo } from "../../../shared/ErrorInfo";
import { EditableDataGrid } from "../../../shared/table/EditableDataGrid";
import { priceRenderer } from "../../../shared/table/renderers/renderCell";
import {
  formatPrice,
  formatWeight,
  generateUUID,
  parseWeight,
} from "../../../shared/utils";
import { IngredientSelect } from "../../ingredients/ingredient-select/ingredient-select";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import { PRODUCT_INGREDIENTS_QUERY } from "./product-ingredients-table.query";

export const ProductIngredientsTable = (props) => {
  const { loading, error, data } = useQuery(PRODUCT_INGREDIENTS_QUERY, {
    variables: { productId: props.productId },
  });
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);
  const tableRef = useRef(null);
  const [rows, setRows] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const totalPriceReducer = (acc, row) => {
    const amount = parseWeight(
      row.amount?.value ? row.amount.value : row.amount
    );
    const price = parseWeight(
      row.ingredient?.value?.price
        ? row.ingredient.value.price
        : row.ingredient.price
    );

    if (isNaN(amount) || isNaN(price)) {
      return acc;
    }

    return acc + amount * price;
  };

  const onLoaded = props.onLoaded;

  console.log("ProductIngredientsTable", data);

  useEffect(() => {
    if (data?.productIngredients.length > 0) {
      const newRows = data.productIngredients.map((row) => ({
        ...row,
        ingredientName: row.ingredient.name,
        ingredientPrice: row.ingredient.price,
        calculatedPrice: row.amount * row.ingredient.price,
        multiplier: row.ingredient.multiplier,
      }));
      setRows(newRows);
      onLoaded(newRows);
      setTotalPrice(data.productIngredients.reduce(totalPriceReducer, 0));
    } else {
      setRows([
        {
          id: generateUUID(),
          ingredient: null,
          ingredientName: "",
          calculatedPrice: 0,
        },
      ]);
    }
  }, [data]);

  if (loading || data.loading) {
    return <CircularProgress />;
  }
  if (error) return <ErrorInfo error={error} />;

  const columns = [
    {
      field: "ingredient",
      headerName: "Nazwa",
      width: 150,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.ingredientName;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        const { id, value, api, field } = params;

        const handleChange = async (event, data) => {
          api.setEditCellValue({ id, field, value: data }, event);
          api.commitRowChange(id);
        };

        return (
          <IngredientSelect
            value={params.row.ingredient}
            onChange={handleChange}
            error={(params as any).error}
            label={params.colDef.headerName}
          />
        );
      },
    },
    {
      field: "amount",
      headerName: "Ilość użyta do prod.",
      width: 120,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ display: "flex" }}>
            {formatWeight(params.row.amount)}{" "}
            <Box
              sx={{ color: "grey", textIndent: 2, textTransform: "lowercase" }}
            >
              {params.row.ingredient?.unitType}
            </Box>
          </Box>
        );
      },
      valueGetter: (params) => {
        return formatWeight(params.row.amount);
      },
      renderEditCell: (params: GridRenderCellParams) => {
        const { id, value, api, field } = params;

        const handleChange = async (event, data) => {
          api.setEditCellValue({ id, field, value: data }, event);
          api.commitRowChange(id);
        };

        return (
          <TextField
            value={value}
            onChange={(e) => handleChange(e, e.target.value)}
            error={(params as any).error}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ textTransform: "lowercase" }}
                >
                  {params.row.ingredient?.unitType}
                </InputAdornment>
              ),
            }}
            label={"0"}
          />
        );
      },
    },
    {
      field: "ingredientPrice",
      headerName: "Cena skł.",
      width: 80,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
      valueGetter: (params) => {
        return params.row.ingredient?.price ?? "-";
      },
    },
    {
      field: "calculatedPrice",
      headerName: "Wartość",
      width: 80,
      editable: false,
      type: "number",
      renderCell: priceRenderer,
      valueGetter: (params) => {
        return params.row.ingredient?.price
          ? formatPrice(
              parseWeight(params.row.amount) * params.row.ingredient?.price
            )
          : "-";
      },
    },
    {
      field: "actions",
      headerName: "Usuń",
      resizable: false,
      width: 50,
      cellClassName: "actions print-hidden",
      headerClassName: "actions print-hidden",
      filterable: false,
      renderCell: (row) => {
        //console.log(this);
        if (!props.editMode) {
          return [];
        }

        return [
          <IconButton
            key={row.id}
            //disabled={rowData.isSaved}
            className="textPrimary cancel-action"
            onClick={async () => {
              const newRows = rows.filter((r) => r.id !== row.id);
              setRows(newRows);

              let rowsEditModels = Object.entries(row.api.getEditRowsModel());
              rowsEditModels = rowsEditModels.filter((r) => r[0] !== row.id);
              const _rows = rowsEditModels.map((r: any) => r[1]);

              setTotalPrice(_rows.reduce(totalPriceReducer, 0));
              props.onRowEditCommit(rowsEditModels.map((r) => r[1]));
            }}
            color="inherit"
          >
            <CancelIcon />
          </IconButton>,
        ];
      },
    },
  ];

  return (
    <Box sx={{ mt: 2, mb: 3, position: "relative" }}>
      <EditableDataGrid
        onInit={(apiRef) => {
          tableRef.current = apiRef;
          props.onInit(apiRef);
        }}
        modelType="productIngredients"
        noPaper={true}
        nameColumn="name"
        title=""
        refetchQueries={["ProductIngredientsQuery"]}
        onRowEditCommit={(_rows) => {
          setTotalPrice(_rows.reduce(totalPriceReducer, 0));
          props.onRowEditCommit(_rows);
        }}
        onRowEditStop={() => {
          props.onEnterPress();
        }}
        key={rows.map((r) => r.id).join(",")}
        onSave={(data, done, err) => {}}
        onRemove={(data, done, err) => {
          removeResource({
            variables: {
              id: [data.id],
              type: "productIngredients",
            },
            refetchQueries: ["ProductIngredientsQuery"],
          })
            .then(done)
            .catch(err);
        }}
        batchActionComponents={[
          (toolbarProps) =>
            props.editMode && (
              <Button
                variant="outlined"
                color="success"
                size="small"
                sx={{ justifySelf: "flex-start" }}
                startIcon={<Add />}
                onClick={() => {
                  let rowsEditModels = Object.entries(
                    tableRef.current.current.getEditRowsModel()
                  );
                  const _rows = rowsEditModels.map((r: any[]) => {
                    return {
                      id: r[0],
                      amount: r[1].amount.value,
                      ingredient: r[1].ingredient.value,
                      ingredientName: r[1].ingredient.value.name,
                      ingredientPrice: r[1].ingredient.value.price,
                      calculatedPrice:
                        r[1].amount.value * r[1].ingredient.value.price,
                      multiplier: r[1].ingredient.value.multiplier,
                    };
                  });

                  setRows([
                    ..._rows,
                    {
                      id: generateUUID(),
                      ingredient: null,
                      ingredientName: "",
                      calculatedPrice: 0,
                      createdAt: new Date().toISOString(),
                    },
                  ]);
                }}
                {...toolbarProps}
              >
                Dodaj składnik
              </Button>
            ),
        ]}
        rows={rows}
        noActions={true}
        editModeOn={props.editMode}
        noAdding={true}
        columns={columns}
        checkboxSelection={false}
        sx={{
          "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
            backgroundColor: "white",
            borderTop: "0",
            borderBottom: "1px solid #80808038",
          },
          "& .MuiDataGrid-row--editing": {
            boxShadow: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            minHeight: "30px",
            border: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "0",
            borderLeft: "0",
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          gap: 2,
          bottom: "4px",
          left: "303px",
        }}
      >
        <Box>Koszt składników:</Box>
        <Box sx={{ fontWeight: "600" }}>
          {priceRenderer({
            value: totalPrice,
          })}
        </Box>
      </Box>
    </Box>
  );
};
