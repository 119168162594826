import { useMutation } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "../../../shared/confirmation-dialog/ConfirmationDialog";
import {
  priceRenderer,
  weightRenderer,
} from "../../../shared/table/renderers/renderCell";
import { formatPrice, formatWeight, parsePrice } from "../../../shared/utils";
import { REMOVE_RESOURCE_MUTATION } from "../../mutations/mutations";
import { IngredientCategorySelect } from "../ingredient-category-select/ingredient-category-select";
import { IngredientUnitTypeSelect } from "../ingredient-unit-type-select/ingredient-unit-type-select";
import { UPSERT_INGREDIENT } from "./ingredient.query";

export const IngredientForm = (props) => {
  const [isDisabled, setDisabled] = useState(!props.editMode);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [updateIngredient] = useMutation(UPSERT_INGREDIENT);
  const [removeResource] = useMutation(REMOVE_RESOURCE_MUTATION);
  const [openSnackbar, setOpenSnackbar] = useState("");
  const navigate = useNavigate();
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      ...props.ingredient,
      price: formatPrice(props.ingredient.price) || 0,
    },
  });
  const editorRef = useRef(null);
  const editorRefDesc = useRef(null);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ p: 2, pt: 0, display: "flex", gap: 2, flexDirection: "column" }}
    >
      <Snackbar
        open={!!openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar("")}
      >
        <Alert
          variant="filled"
          className="snackbar-ok"
          severity="error"
          sx={{ width: "100%" }}
        >
          {openSnackbar}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        title="Usuwanie składnika"
        open={confirmationOpen}
        text={props.ingredient.name}
        onYes={() => {
          removeResource({
            variables: {
              id: [props.ingredient.id],
              type: "ingredient",
            },
            refetchQueries: [],
          }).then(() => {
            setConfirmationOpen(false);
            navigate(`/ingredients`, {
              replace: true,
            });
          });
        }}
        onNo={() => {
          setConfirmationOpen(false);
        }}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1, gap: 1 }}
          >
            {isDisabled && (
              <Button
                data-cy="project-edit-button"
                onClick={() => {
                  setDisabled(false);
                }}
                variant="contained"
              >
                Edytuj
              </Button>
            )}
            {isDisabled && (
              <Button
                data-cy="project-edit-button"
                onClick={() => {
                  setConfirmationOpen(true);
                }}
                variant="outlined"
                color="error"
              >
                Usuń
              </Button>
            )}
            {!isDisabled && (
              <>
                <Button
                  data-cy="project-save-button"
                  onClick={() => {
                    handleSubmit(async (data) => {
                      updateIngredient({
                        variables: {
                          id: data.id,
                          eanCode: data.eanCode,
                          name: data.name,
                          description: editorRefDesc.current.getContent(),
                          categoryId: data.category.id,
                          unitType: data.unitType,
                          packageWeight: parseFloat(data.packageWeight),
                          multiplier: parseFloat(data.multiplier),
                          price: parsePrice(data.price),
                          allergensText: editorRef.current.getContent(),
                          isAllergen: data.isAllergen,
                        },
                        refetchQueries: ["IngredientQuery"],
                      })
                        .then((resp) => {
                          if (props.editMode) {
                            navigate(
                              `/ingredients/${resp.data.upsertIngredient.id}`,
                              {
                                replace: true,
                              }
                            );
                          } else {
                            setDisabled(true);
                          }
                        })
                        .catch((err) => {
                          setOpenSnackbar(err.message);
                        });
                    })();
                  }}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Zapisz
                </Button>

                <Button
                  data-cy="project-cancel-button"
                  onClick={() => {
                    if (props.editMode) {
                      navigate(`/ingredients`, {
                        replace: true,
                      });
                    } else {
                      setDisabled(true);
                      reset();
                    }
                  }}
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  Anuluj
                </Button>
              </>
            )}
          </Box>
        </Grid>

        <Box>
          <Box>
            {isDisabled && (
              <Typography variant="h5">{props.ingredient.name}</Typography>
            )}
            {!isDisabled && (
              <Controller
                name={"name"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    label="Nazwa"
                    placeholder="Nazwa składnika"
                    helperText={invalid ? "Nazwa wymagana" : ""}
                    required
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </Box>

          <Box sx={{ color: "#808080c4" }}>
            {isDisabled && props.ingredient.eanCode}
            {!isDisabled && (
              <Box sx={{ mt: "15px" }}>
                <Controller
                  name={"eanCode"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <TextField
                      onChange={onChange}
                      value={value}
                      required
                      label="EAN"
                      error={invalid}
                      placeholder="EAN"
                      helperText={invalid ? "EAN wymagany" : ""}
                      size="small"
                      fullWidth
                      disabled={isDisabled}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={2}>
          Kategoria:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && props.ingredient.category?.name}
          {!isDisabled && (
            <Box>
              <Controller
                name={"category"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <IngredientCategorySelect
                    onChange={onChange}
                    value={value?.id}
                    error={invalid}
                    disabled={isDisabled}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={2}>
          Jednostka opakowania:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && props.ingredient.unitType}
          {!isDisabled && (
            <Box>
              <Controller
                name={"unitType"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <IngredientUnitTypeSelect
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    disabled={isDisabled}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={2}>
          Waga opakowania:
        </Grid>
        <Grid item xs={3}>
          {isDisabled &&
            weightRenderer({ value: props.ingredient.packageWeight })}
          {!isDisabled && (
            <Box>
              <Controller
                name={"packageWeight"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={formatWeight(value)}
                    error={invalid}
                    required
                    placeholder="Waga"
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={2}>
          Przelicznik wagowy:
        </Grid>
        <Grid item xs={3}>
          {isDisabled && props.ingredient.multiplier}
          {!isDisabled && (
            <Box>
              <Controller
                name={"multiplier"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    required
                    error={invalid}
                    placeholder="Liczba"
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={2}>
          Alergen:
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {watch("isAllergen") ? "Tak" : "Nie"}
            {!isDisabled && (
              <Box>
                <Controller
                  name={"isAllergen"}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      required
                      size="small"
                      disabled={isDisabled}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Grid item xs={2} sx={{ fontWeight: "bolder" }}>
          Cena jednostkowa netto
        </Grid>
        <Grid item xs={3}>
          {isDisabled && priceRenderer({ value: props.ingredient.price })}
          {!isDisabled && (
            <Box>
              <Controller
                name={"price"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    placeholder="zł"
                    size="small"
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid
        item
        xs={12}
        sx={{
          ".tox-tinymce": {
            borderRadius: "4px",
            border: "1px solid rgb(0 0 0 / 24%)",
          },
        }}
      >
        <Typography sx={{ pb: 1, mt: 3 }}>Skład</Typography>
        {isDisabled && (
          <Box
            dangerouslySetInnerHTML={{
              __html: props.ingredient.allergensText || "-",
            }}
          ></Box>
        )}
        {!isDisabled && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={props.ingredient.allergensText}
            init={{
              autoresize_bottom_margin: 10,
              menubar: false,
              plugins: [
                "autoresize",
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "blocks bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat code | help",
            }}
          />
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          ".tox-tinymce": {
            borderRadius: "4px",
            border: "1px solid rgb(0 0 0 / 24%)",
          },
        }}
      >
        <Typography sx={{ pb: 1, mt: 3 }}>Opis</Typography>
        {isDisabled && (
          <Box
            dangerouslySetInnerHTML={{
              __html: props.ingredient.description || "-",
            }}
          ></Box>
        )}
        {!isDisabled && (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRefDesc.current = editor)}
            initialValue={props.ingredient.description}
            init={{
              autoresize_bottom_margin: 10,
              menubar: false,
              plugins: [
                "autoresize",
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "blocks bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat code | help",
            }}
          />
        )}
      </Grid>
    </Box>
  );
};
