import { gql, useQuery } from "@apollo/client";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { ProductCategory } from "@prisma/client";
const PRODUCT_CATEGORIES_QUERY = gql`
  query ProductCategoriesQuery {
    productCategories {
      id
      name
      margin
    }
  }
`;

interface IProps {
  name?: string;
  value?: string;
  onChange?: (e: any, ev: any) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  variant?: "standard" | "outlined";
}

export const ProductCategorySelect: React.FC<IProps> = (props) => {
  const { loading, error, data } = useQuery(PRODUCT_CATEGORIES_QUERY);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <TextField
      select
      fullWidth
      name={props.name}
      variant={props.variant}
      size="small"
      label={props.label}
      disabled={props.disabled}
      required={props.required}
      placeholder="Kategoria"
      helperText={props.error ? "Kategoria wymagana" : ""}
      error={props.error}
      value={props.value}
      onChange={(ev) => {
        props.onChange(
          data.productCategories.find((p) => p.id === ev.target.value),
          ev
        );
      }}
    >
      {data.productCategories.map((productCategory: ProductCategory) => (
        <MenuItem key={productCategory.id} value={productCategory.id}>
          {productCategory.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
