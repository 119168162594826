import { Grid, Paper } from "@mui/material";
import Title from "../../../shared/Title";
import { IngredientForm } from "./ingredient.form";

export const NewIngredientPage = () => {
  return (
    <Paper
      sx={{
        p: 1,
        m: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={1} sx={{ minWidth: 600 }}>
        <Grid item xs={6} className="project-name">
          <Title data-cy="project-title">Nowy składnik</Title>
        </Grid>
      </Grid>

      <IngredientForm
        ingredient={{
          name: "",
          category: null,
          price: null,
          unitType: "KG",
        }}
        editMode={true}
      />
    </Paper>
  );
};
