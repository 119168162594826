import { gql, useQuery } from "@apollo/client";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { IngredientCategory } from "@prisma/client";
const INGREDIENT_CATEGORIES_QUERY = gql`
  query IngredientCategoriesQuery {
    ingredientCategories {
      id
      name
    }
  }
`;

interface IProps {
  name?: string;
  value?: string;
  onChange?: (e: any, ev: any) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  variant?: "standard" | "outlined";
}

export const IngredientCategorySelect: React.FC<IProps> = (props) => {
  const { loading, error, data } = useQuery(INGREDIENT_CATEGORIES_QUERY);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <TextField
      select
      fullWidth
      name={props.name}
      variant={props.variant}
      size="small"
      label={props.label}
      disabled={props.disabled}
      placeholder="Wybierz kategorię"
      helperText={props.error ? "Kategoria jest wymagana" : ""}
      error={props.error}
      required
      value={props.value}
      onChange={(ev) => {
        props.onChange(
          data.ingredientCategories.find((p) => p.id === ev.target.value),
          ev
        );
      }}
    >
      {data.ingredientCategories.map(
        (ingredientCategory: IngredientCategory) => (
          <MenuItem key={ingredientCategory.id} value={ingredientCategory.id}>
            {ingredientCategory.name}
          </MenuItem>
        )
      )}
    </TextField>
  );
};
